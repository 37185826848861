.partner-activities-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: 'Oswald', sans-serif;
  width: 100%;
}

.partner-activities-page .container {
  width: 100%;
}

.partner-activities-page .table {
  font-family: 'Oswald', sans-serif;
  font-size: large;
}

.partner-activities-page .model-header {
  padding-top: 7px;
}

.partner-activities-page .modal-header .close {
  height: 15%;
  width: 15%;
}
