.button-container {
  display: flex;
  justify-content: space-between; /* Align buttons with equal space between them */
  width: 100%;
}

.error-lock-button {
  flex: 1;
  width: calc(50% - 40px);
  height: 55px !important;
  margin: 15px 20px 5px !important;
  border-radius: 15px !important;
  font-size: 24pt;
  background-color: white;
  border: 3px solid gray;
  font-family: 'Oswald' !important;
  font-weight: 400;
  text-transform: uppercase !important;
  transition: all 0.2s ease-in-out;
}

.error-indicator {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.error-indicator img {
  border-radius: 40px;
  width: 20%;
  margin: 5px 0;
}

.error-indicator div {
  color: white;
  font-size: 30px;
  font-family: 'Oswald', sans-serif;
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin: 100px auto 0; */
  width: 150px;
  text-align: center;
}

.spinner > div {
  width: 30px;
  height: 30px;
  margin: 10px;
  background-color: #e8ddb7;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

button > .loading-indicator > .spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin: 100px auto 0; */
  width: auto;
  text-align: center;
}

button > .loading-indicator > .spinner > div {
  width: 10px;
  height: 10px;
  margin: 5px;
  background-color: #e8ddb7;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

button > .loading-indicator > .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

button > .loading-indicator > .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
