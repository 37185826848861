.activities-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: 'Oswald', sans-serif;
  width: 100%;
}

.activities-page .container {
  width: 100%;
}

.activities-page .table {
  font-family: 'Oswald', sans-serif;
  font-size: large;
}

.activities-page .model-header {
  padding-top: 7px;
}

.activities-page .modal-header .close {
  height: 15%;
  width: 15%;
}

.multiselector {
  flex: 1 1;
}

.multiselector div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
