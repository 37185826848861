.purchase-page {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../../../images/wood.jpg');
  padding: 40px;
  box-sizing: border-box;
}

.purchase-page .button-container button {
  padding: 20px;
  /* width: 60%; */
  /* margin-bottom: 20px;
    margin-top: 20px; */
  /* display: flex;
    position: relative; */
  /* left: 130px; */
  /* box-sizing: border-box; */
  font-size: 24pt;
  background-color: white;
  border-radius: 15px;
  border: 2px solid gray;
  justify-content: center;
  width: 100%;
  /* margin: auto; */
}

.purchase-page .button-container {
  width: 60%;
  /* margin: auto; */
  /* position: relative; */
  /* float: left; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 10px; */
  height: 80%;
  margin: auto;
  margin-bottom: 140px;
}

.purchase-page .cart-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
}

.purchase-page .cart-item-container {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: lightgray;
  border-radius: 15px;
  border: 2px solid gray;
  overflow: scroll;
  height: 100%;
}

.purchase-page .cart-container .checkout-button {
  padding: 20px;
  width: 40%;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  box-sizing: border-box;
  font-size: 24pt;
  background-color: white;
  border-radius: 15px;
  border: 2px solid gray;
  justify-content: center;
  margin: 20px auto;
}

.purchase-top-scroll-view-container {
  /* height: 90%; */
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.purchase-menu-list {
  width: 50%;
  height: 100%;
}

.purchasePageButtonRowContain {
  height: calc(10% - 10px);
  width: calc(100% - 10px);
  margin: 5px;
}

.purchasePageButtonContain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33%;
  height: 100%;
  float: left;
}

.purchasePageButton {
  display: inline-block;
  border-radius: 20px;
  background-color: white;
  font-size: 24px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border: 3px solid gray;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.35s;
}

.purchasePageButton:disabled {
  background-color: darkgray;
  cursor: not-allowed;
  transition: all 0.35s;
}

.purchase-cart h2 {
  color: white;
}

.bottom-container {
  display: flex;
  flex-direction: row;
}
