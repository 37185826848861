.inventory-page {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: pink; */
  background-image: url('../../../images/wood.jpg');
}

.inventory-page .inventory-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  width: 70%;
  border: 3px solid gray;
  padding: 30px;
  border-radius: 50px;
  background-color: white;
}

.inventory-page .inventory {
  margin: auto;
  width: 100%;
}

.inventory-page .inventory-results {
  display: flex;
  flex-direction: column;
  height: 475px;
  overflow: scroll;
  padding: 10px;
  border: 2px solid gray;
  border-radius: 15px;
}

.inventory-page .inventory-results .item-info {
  padding: 5px;
  border: 1px solid gray;
  border-radius: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: 0.25s all ease-in-out;
  display: flex;
  flex: 1;
}

.inventory-page .inventory-results .item-info div {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex: 1;
  font-size: 13pt;
}

.inventory-page .inventory-results .ready {
  background-color: rgb(228, 250, 228);
}

.inventory-page .inventory-results .warning {
  background-color: rgb(250, 250, 223);
}

.inventory-page .inventory-results .broken {
  background-color: rgb(241, 217, 217);
}

.inventory-page .inventory-header {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin: auto;
  justify-content: center;
  text-align: center;
}

.inventory-page .inventory-header div {
  flex: 1;
  margin: auto;
  text-align: center;
  font-size: 16pt;
  font-weight: bold;
  margin-bottom: 10px;
}

.inventory-page .item-options {
  display: flex;
  flex: 1;
}

.inventory-page .inventory-results .item-info .item-options button {
  background-color: #eee;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* min-width: 75px; */
  font-size: 12pt;
  margin: auto 0px auto auto;
  flex: 1;
  max-width: 80px;
  width: 100%;
}

.inventory-page .back-button {
  padding: 20px;
  margin-top: 20px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  font-size: 24pt;
  border-radius: 15px;
  border: 2px solid gray;
  transition: all 0.5s ease-out;
  width: 35%;
  position: relative;
  text-align: center;
  display: block;
  background-color: #333333;
  color: lightgray;
  border-color: lightgray;
  margin-left: auto;
  margin-right: auto;
}
