.collectivePageImageContain {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theBayLogo {
  max-height: 100%;
}

.collectivePageColumn {
  width: 50%;
  height: 100%;
  float: left;
}

.homeFeed {
  height: calc(100% - 50px);
}
