.not-found {
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white !important;
  flex-direction: column;
}

.backButton {
  margin: 15px auto !important;
  border-radius: 20px !important;
  background-color: white !important;
  color: rgb(99, 99, 99) !important;
  font-size: 24px !important;
  width: calc(23% - 6px) !important;
  height: calc(10% - 6px) !important;
  border: 3px solid darkgray !important;
  font-family: 'Oswald', sans-serif !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  transition: all 0.35s !important;
}
