.addUserPage {
  font-size: 24px;
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  margin: 5px;
  padding: 10px;
  border: 3px solid gray;
  border-radius: 20px;
  overflow: auto;
  -webkit-overflow-scrolling: auto;
  background-color: lightgray;
}

.addUserPageWithLabels {
  margin: 0 5px 5px 5px;
  height: calc(92% - 32px);
}

.addUserLabelContain {
  width: calc(100% - 46px);
  height: calc(8% - 5px);
  margin: 5px 5px 0 5px;
  padding: 0 18px;
  /* width: 100%; */
}

.addUserLabel {
  text-align: center;
  display: flex;
  justify-content: left;
  padding-left: 10px;
  align-items: flex-end;
  font-weight: bold;
  margin-bottom: 5px;
  height: calc(100% - 5px);
  color: black;
  float: left;
  white-space: nowrap;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}

.addUserPageOption {
  height: 60px;
  width: 100%;
  border-radius: 20px;
  /* background-color: lightgray; */
  margin-bottom: 10px;
  /* padding: 5px 0 105px 0; */
  border: 2px solid gray;
  background-color: white;
  box-sizing: border-box;
  transition: all 0.2s ease-out;
  font-family: 'Lato', sans-serif;
  white-space: nowrap;
}

.addUserPageOptionSelected {
  background-color: #e8ddb7;
  box-shadow: 2px 2px 4px gray;
  transform: translate(-2px, -2px);
  /* transform: scaleX(1.005); */
  transition: all 0.2s ease-out;
}

.addUserPageOptionCol {
  display: flex;
  flex-basis: 50%;
  justify-content: left;
  padding-left: 10px;
  align-items: center;
  font-weight: bold;
  height: 100%;
  color: black;
  float: left;
  overflow: hidden;
}

.addUserPageOptionCol p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.filterOptionSelected {
  background-color: #e8ddb7 !important;
}

.addUserButtonsContain {
  height: calc(10% - 10px);
  width: calc(100% - 10px);
  margin: 5px;
}

.addUserButtonContain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33%;
  height: 100%;
  float: left;
}

.addUserButton {
  display: inline-block;
  border-radius: 20px;
  background-color: white;
  font-size: 24px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border: 3px solid gray;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.35s;
}

.addUserButton:disabled {
  background-color: darkgray;
  cursor: not-allowed;
  transition: all 0.35s;
}

.addUserSearchBar {
  font-size: 20pt;
  /*transition: all 0.2s ease-out;*/
  width: calc(100% - 46px);
  height: calc(10% - 16px);
  overflow: scroll;
  display: inline-block;
  margin: 5px;
  padding: 0 15px;
  border: 3px solid gray;
  border-radius: 20px;
  background-color: white;
  /* width: 100%; */
}

.addUserPageLabelContain h1,
.addUserPage h1 {
  margin: auto;
  font-size: 16pt;
  font-weight: bold;
  color: #777777;
  justify-content: center;
  text-align: center;
  margin-top: 25px;
}

.addUserColumn {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 5px;
}

.addUserRow {
  display: flex;
  flex-direction: row;
}

.addUserInputLabel {
  font-weight: bold;
  font-size: 27px;
  margin-left: 5px;
}

.addUserInput {
  height: 50px;
  width: calc(100% - 20px);
  border: 3px solid gray;
  border-radius: 15px;
  font-size: 24px;
  margin-bottom: 25px;
  padding: 0 10px;
}
